var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    { staticClass: "side-bar-bg", attrs: { app: "", width: "200" } },
    [
      _c("v-layout", {
        attrs: { "align-center": "", "justify-space-around": "" },
      }),
      _c("div", { staticClass: "text-center team-name" }, [
        _c("div", { staticClass: "name-short" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.teamBio.team_name
                  ? _vm.abbrevateName(_vm.teamBio.team_name).toUpperCase()
                  : ""
              ) +
              "\n    "
          ),
        ]),
        _c("div", { staticClass: "team-username" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.teamBio.team_name
                  ? _vm.truncate(_vm.teamBio.team_name, 10)
                  : ""
              ) +
              "\n    "
          ),
        ]),
      ]),
      _c("div", { staticClass: "sb-divider" }),
      _c(
        "div",
        { staticClass: "wrap_tab", attrs: { id: "tab-section" } },
        [
          _vm._l(_vm.tabs, function (tab, index) {
            return _c(
              "div",
              {
                key: tab.icon,
                staticClass: "single-tab",
                class: { "selected-tab": tab.active },
              },
              [
                _c(
                  "router-link",
                  {
                    key: index,
                    staticClass: "sb-tab-menu c-pointer",
                    attrs: {
                      exact: "",
                      to: { name: tab.route_name },
                      tag: "div",
                    },
                  },
                  [
                    _c("div", { staticClass: "tab-cover" }, [
                      _c("span", { staticClass: "opc" }, [
                        _c("img", { attrs: { src: tab.icon } }),
                      ]),
                      _c("span", { staticClass: "tab-name" }, [
                        _vm._v(_vm._s(tab.name)),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "single-tab bottom-tab",
              on: {
                "!click": function ($event) {
                  return _vm.handleDropdown.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "sb-tab-menu c-pointer",
                  attrs: {
                    exact: "",
                    tag: "div",
                    to: { name: "dev_profile" },
                    "active-class": "selected-tab",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tab-cover menu-wrapper" },
                    [
                      _vm.isViewProfile
                        ? _c(
                            "LogoutMenu",
                            _vm._b(
                              {},
                              "LogoutMenu",
                              {
                                user: _vm.user,
                                truncate: _vm.truncate,
                                closeLogoutMenu: _vm.closeLogoutMenu,
                              },
                              false
                            )
                          )
                        : _vm._e(),
                      _c("v-avatar", {
                        staticClass: "profile_bg",
                        style: {
                          backgroundImage:
                            "url(" +
                            (_vm.$imageUrl + _vm.user.profile_picture ||
                              _vm.imageAvatar) +
                            ")",
                        },
                        attrs: { size: 32, color: "grey lighten-4" },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "tab-name",
                          staticStyle: { "margin-left": "8px" },
                        },
                        [_vm._v(_vm._s(_vm.truncate(_vm.user.name, 16)))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }