<template>
  <div>
    <div class="dashboard-container">
      <Sidebar :tabs="sideBarTabs" />
      <div class="project-layout">
        <Toolbar v-if="$route.name !== 'dev_workspace'" v-bind="{ tabs }" />
        <v-content>
          <router-view></router-view>
        </v-content>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Toolbar from "./toolbar";
import Sidebar from "./sidebar";

export default {
  components: {
    Toolbar,
    Sidebar,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: "Workspace",
          active: true,
          route_name: "dev_workspace",
        },
        {
          id: 2,
          name: "Roadmap",
          active: false,
          route_name: "roadmap",
        },
        {
          id: 3,
          name: "Analytics",
          active: false,
          route_name: "team_analytics",
        },
        {
          id: 4,
          name: "Documents",
          active: false,
          route_name: "team_doc",
        },
        {
          id: 5,
          name: "Team Mgt",
          active: false,
          route_name: "team_mgt",
        },
        // {
        //   id: 6,
        //   name: "Schedule Meetings",
        //   active: false,
        //   route_name: "schedule_meetings",
        // },
      ],
      sideBarTabs: [
        {
          id: 1,
          name: "Overview",
          icon: "/img/dashboard/icons/overview.svg",
          active: false,
          route_name: "dev_dashboard",
        },
        {
          id: 2,
          name: "Products",
          icon: "/img/dashboard/icons/products.svg",
          active: true,
          route_name: "dev_projects",
        },
        {
          id: 3,
          name: "Wallet",
          icon: "/img/dashboard/icons/wallet.svg",
          active: false,
          route_name: "dev_wallet",
        },
        {
          id: 4,
          name: "Team",
          icon: "/img/dashboard/icons/team.svg",
          active: false,
          route_name: "team",
        },
        {
          id: 5,
          name: "Support",
          icon: "/img/dashboard/icons/support.svg",
          active: false,
          route_name: "dev_help",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<style>
</style>