var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "dashboard-container" },
      [
        _c("Sidebar", { attrs: { tabs: _vm.sideBarTabs } }),
        _c(
          "div",
          { staticClass: "project-layout" },
          [
            _vm.$route.name !== "dev_workspace"
              ? _c("Toolbar", _vm._b({}, "Toolbar", { tabs: _vm.tabs }, false))
              : _vm._e(),
            _c("v-content", [_c("router-view")], 1),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }